
import React  from "react";
import "./PublicPageLayout.css";
import TopNavbar from "../TopNavbar/TopNavbar";
import Footer from "../Footer/Footer";

const PublicPageLayout = (props) =>
{
    return (
        <>
            <TopNavbar />
            {props.children}
            <div className="ml-auto mr-auto text-center mt-10 mb-20">
                
            </div>
        </>
    );
}

export default PublicPageLayout;