import './TopNavbar.css';
import { Link } from 'react-router-dom';
import { Paths } from '../../Constants/pagesRoute';

const TopNavbar = () =>
{
    return (
        <nav className="TopNav">
            <div className="FlexLeft">
            <img className="Sign" src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhHHn4a5iNRf8CPk5sNb0YzKXB5cy4DKoCo75CATSPx8QwXie7klUFI64GeKKUuMRX6qRZMPV9qjUWVSFmYJ90OV9s5nb_l93dOXVt2xpo-ZujR-aQUUIWE0QL3b0buC5O8HQW_ME2a7YcJCPvfqW8F0lsVPHdFesbvDyircMDAspeLa58y28jERY0CK10/s320/Podpis%20.png" />
            </div>
            <div className="FlexRight">
            <div className="Link">
                <div className="DropDown">
                <div className="DropdownButton">Astro</div>
                <div className="DropdownContent">
                    <Link href="#" className="Link">Noční krajina</Link>
                    <Link href="#" className="Link">Měsíc</Link>
                    <Link href="#" className="Link">Deep Sky</Link>
                    <Link href="#" className="Link">Mléčná Dráha</Link>
                </div>
                </div>
            </div>
            <div className="Link">
                <div className="DropDown">
                <div className="DropdownButton">Svatby</div>
                <div className="DropdownContent">
                    <Link href="#" className="Link">Svatební Fotografie</Link>
                    <Link href="#" className="Link">Svatební Příběhy</Link>
                </div>
                </div>
            </div>
            <div className="Link">
                <div className="DropDown">
                <div className="DropdownButton">Galerie</div>
                <div className="DropdownContent">
                    <Link href="#" className="Link">Portréty</Link>
                    <Link href="#" className="Link">Rodina</Link>
                    <Link href="#" className="Link">Nemovitosti</Link>
                    <Link href="#" className="Link">Zvířata</Link>
                    <Link href="#" className="Link">Krajiny</Link>
                </div>
                </div>
            </div>
            <div className="Link">
                <Link href="#" className="Link">Blog</Link>
            </div>
            <div className="Link">
                <Link href="#" className="Link">Ceník</Link>
            </div>
            <div className="Link">
                <Link href="#" className="Link">Kontakt</Link>
            </div>
            </div>
        </nav>
    )
}

export default TopNavbar;