/* 
    description: Routovaní a zabezpečení stránek
    autor: David Michalica
*/

import React, { Component } from 'react';
import {Route, Switch} from 'react-router-dom';
import {Paths} from "./Constants/pagesRoute";
import { PageComponents } from './Pages/ExportPages';

class App extends Component
{
  render()
  {
    return (
      <Switch>
        <Route exact path={Paths.homePath} component={PageComponents.HomePageComponent} />
        <Route path='*'>
          <>You have landed on a page that doesn't exist</>
        </Route>
      </Switch>
    );
  }  
}

export default App;
