import './HomePage.css';
import React, { Component } from "react";
import PublicPageLayout from "../../../Components/PublicPageLayout/PublicPageLayout"

class HomePage extends React.Component
{
    render()
    {
        return (
            <PublicPageLayout >
                <h1 className=''>Úvodní stránka</h1>
            </PublicPageLayout>
        );
    }
}

export default HomePage;